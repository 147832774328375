<template>
  <fieldset id="mvp-wysiwyg">
    <legend>
      <i class="fas fa-file-alt accent--text"></i>
      Description
    </legend>

    <!-- Editor Menu -->
    <editor-menu-bar :editor="editor">
      <aside class="editor-toolbar">
        <button
          :class="action.dynamicClass"
          :key="i"
          @click.prevent="action.click"
          type="button"
          v-for="(action, i) in actions"
        >
          <i :class="action.icon" />
        </button>
      </aside>
    </editor-menu-bar>

    <!-- Editor Body -->
    <editor-content class="editor-content" :editor="editor" />
  </fieldset>
</template>

<script>
import FormsMixin from "./mixins/forms.mixin";
import { Editor } from "tiptap";
import {
  Blockquote,
  CodeBlock,
  HardBreak,
  Heading,
  HorizontalRule,
  OrderedList,
  BulletList,
  ListItem,
  TodoItem,
  TodoList,
  Bold,
  Code,
  Italic,
  Link,
  Strike,
  Underline,
  History
} from "tiptap-extensions";

export default {
  name: "MvpWysiwyg",

  mixins: [FormsMixin],

  props: { placeholder: { type: String, default: "Enter description here" } },

  watch: {
    formData: function _onFormData(newData) {
      if (!this.editor) return;
      if (newData.description !== this.editor.getHTML()) {
        this.setEditorContent(newData.description);
      }
    }
  },

  data() {
    const editorOpts = {
      content: this.description || `<i>${this.placeholder} here</i>`,
      extensions: [
        new Blockquote(),
        new CodeBlock(),
        new HardBreak(),
        new Heading(),
        new HorizontalRule(),
        new OrderedList(),
        new BulletList(),
        new ListItem(),
        new TodoItem(),
        new TodoList(),
        new Bold(),
        new Code(),
        new Italic(),
        new Link(),
        new Strike(),
        new Underline(),
        new History()
      ],
      onUpdate: ({ getHTML }) => this.appendAndEmit({ description: getHTML() })
    };

    const editor = new Editor(editorOpts);

    return { editor };
  },

  computed: {
    actions() {
      if (!this.editor) return [];

      const { commands, isActive } = this.editor;
      const basic = ["bold", "italic", "underline" /* , "paragraph" */];
      const dynamicClass = button => ({
        dynamicClass: { button, outline: !button }
      });

      return [
        //   Basic commands
        ...basic.map(c => ({
          ...dynamicClass(isActive[c]()),
          click: commands[c],
          icon: `fas fa-${c}`
        })),
        //   Headings
        ...[1, 2, 3].map(level => ({
          click: () => commands.heading({ level }),
          icon: `fas fa-h${level}`,
          ...dynamicClass(isActive.heading({ level }))
        })),
        //   Others
        {
          click: commands.blockquote,
          icon: "fas fa-quote-left",
          ...dynamicClass(isActive.blockquote())
        },
        {
          click: commands.strike,
          icon: "fas fa-strikethrough",
          ...dynamicClass(isActive.strike())
        },
        {
          click: commands.bullet_list,
          icon: "fas fa-list-ul",
          ...dynamicClass(isActive.bullet_list())
        },
        {
          click: commands.ordered_list,
          icon: "fas fa-list-ol",
          ...dynamicClass(isActive.ordered_list())
        }
      ];
    },

    description() {
      return (this.formData || {}).description;
    },

    requiredFields() {
      return ["description"];
    }
  },

  beforeDestroy() {
    this.editor.destroy();
  },

  mounted() {
    if (this.editor) {
      return this.setEditorContent(this.formData.description);
    }
  },

  methods: {
    setEditorContent(content) {
      this.editor.setContent(content || this.placeholder);
    }
  }
};
</script>

<style lang="scss" src="./MvpWysiwyg.scss"></style>
