<template>
  <mvp-wysiwyg
    :form-data="formData"
    :placeholder="placeholder"
    class="mvp-wysiwyg wide"
    @change="appendAndEmit"
  />
</template>

<script>
import FormsMixin from "./mixins/forms.mixin";
import MvpWysiwyg from "./MvpWysiwyg.vue";

export default {
  components: { MvpWysiwyg },

  name: "EventFormDescription",

  props: { placeholder: String },

  mixins: [FormsMixin],

  computed: {
    requiredFields() {
      return ["description"];
    }
  }
};
</script>
